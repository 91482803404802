@use '@assets/sass/variables' as *;

@media #{$max-media-mobile} {
  .wrapper {
    #main-panel {
      &.main-panel > .content {
        padding: 15px;
      }
    }
  }
}
